import axios from "axios";

//get list of all the Subjects
export const getSubjects = async () => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> Subject -> getSubjects");

  return await axios.get(`${process.env.REACT_APP_API}/subjects`); //making request to endpoing Class to list all Class
};

//get details of selected Class
// Get details of the selected Subject (for both logged-in and non-logged-in users)
export const getSubject = async (_id, authtoken = null) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> Subject -> getSubject");

  const config = {};
  if (authtoken) {
    config.headers = {
      authtoken: authtoken,
    };
  }

  return await axios.get(`${process.env.REACT_APP_API}/subject/${_id}`, config);
};

//remove selected Class
export const removeSubject = async (_id, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> Subject -> removeSubject");

  return await axios.delete(`${process.env.REACT_APP_API}/subject/${_id}`, {
    headers: {
      authtoken,
    },
  }); //making request to endpoing Class with Slug to find given Class details adn delete it
};

//update details of selected Class
export const updateSubject = async (_id, subject, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> Subject -> updateSubject");

  return await axios.put(
    `${process.env.REACT_APP_API}/subject/${_id}`,
    subject,
    {
      headers: {
        authtoken,
      },
    }
  ); //making request to endpoing Class with Slug to find given Class details
};

//create a new Class
export const createSubject = async (subject, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> Subject -> createSubject");

  return await axios.post(`${process.env.REACT_APP_API}/subject`, subject, {
    headers: {
      authtoken: authtoken,
    },
  }); //making request to endpoing Class with Slug to find given Class details
};

export const getSubjectsByClass = async (_id) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> Subject -> getSubjectsByClass");

  try {
    console.log("Fetching subjects for classId:", _id);
    const response = await axios.get(
      `${process.env.REACT_APP_API}/subjects?classId=${_id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};
export const getSubjectsByExam = async (_id) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> Subject -> getSubjectsByExam");

  try {
    console.log("Fetching subjects for ExamId:", _id);
    const response = await axios.get(
      `${process.env.REACT_APP_API}/subjects?examId=${_id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

export const getUserSubjects = async (userId, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> Subject -> getUserSubjects");

  console.log("UserId sending to BE - ", userId);
  return await axios.get(
    `${process.env.REACT_APP_API}/user/subjects/${userId}`,
    {
      headers: {
        authtoken: authtoken,
      },
    }
  );
};


//Get User Subscription Status by Subject
export const getSubsStatus = async (_id, userId, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> Subject -> getSubsStatus");

  const headers = {};
  const params = {};

  if (authtoken) {
    headers.authtoken = authtoken;
  }

  if (userId) {
    params.userId = userId;
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/user/subject/${_id}`,
      {
        headers: headers, // Use 'headers' object defined above
        params: params, // Use 'params' object defined above
      }
    );

    console.log("Specific Subject response from backend:", response);
    return response.data;
  } catch (error) {
    console.error("Actual Network/HTTP Error:", error);
    throw error; // Or handle based on your application's logic
  }
};