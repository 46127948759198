import axios from "axios";
import api, { CacheManager } from "./api";

export const createTest = async (testData, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> test -> createTest");

  return axios.post(`${process.env.REACT_APP_API}/test`, testData, {
    headers: {
      authtoken: authtoken,
    },
  });
};

export const createChapterTest = async (testData, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> test -> createTest");

  return axios.post(`${process.env.REACT_APP_API}/chaptertest`, testData, {
    headers: {
      authtoken: authtoken,
    },
  });
};
export const createSubjectTest = async (testData, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> test -> createTest");

  return axios.post(`${process.env.REACT_APP_API}/subjecttest`, testData, {
    headers: {
      authtoken: authtoken,
    },
  });
};

export const getTestExams = async () => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> test -> getTestExams");
  return api.get(`/activetests`);
};

// Function to get active tests by subject ID and exam ID
export const getActiveTestsBySubject = async (subjectId, examId) => {
  console.log(
    "FRONTEND AXIOS FUNCTION NAME -> test -> getActiveTestsBySubject"
  );
  try {
    const response = await api.get(`/activetests/subject/${subjectId}?exam=${examId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching active tests by subject and exam:", error);
    throw error;
  }
};

// Function to get chapters with active tests by chapter ID, subject ID, and exam ID
export const getChaptersWiseActiveTests = async (
  chapterId,
  subjectId,
  examId
) => {
  console.log(
    "FRONTEND AXIOS FUNCTION NAME -> test -> getChaptersWiseActiveTests"
  );
  try {
    const response = await api.get(`/activetests/chapter/${chapterId}?subject=${subjectId}&exam=${examId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching chapters with active tests:", error);
    throw error;
  }
};

// Function to get chapters with active tests by chapter ID, subject ID, and exam ID
export const getExamWiseActiveTests = async (examId) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> test -> getExamWiseActiveTests");
  try {
    const response = await api.get(`/activetests/${examId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching chapters with active tests:", error);
    throw error;
  }
};

// Function to get chapters with active tests by chapter ID, subject ID, and exam ID
export const getTestQuestions = async (testId) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> test -> getTestQuestions");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/activetests/questions/${testId}`
    );
    console.log("response", response.data);
    return response.data; // Return the fetched questions
  } catch (error) {
    console.error("Error fetching test questions:", error);
    throw error; // Propagate the error to the calling function
  }
};
export const getTestDuration = async (testId, userId) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> test -> getTestDuration");
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/activetests/duration/${testId}`,
      {
        params: { userId }, // Correctly pass userId as a query parameter
      }
    );
    return response.data; // Return the fetched data
  } catch (error) {
    console.error("Error fetching test duration:", error);
    throw error; // Propagate the error to the calling function
  }
};

export const submitTest = async (testId, userId, responses) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> test -> submitTest");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/tests/submit`,
      {
        testId,
        userId,
        responses, // Only send the user's responses
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting test:", error);
    throw error;
  }
};

export const getUserTestAttempts = async (authtoken) => {
  try {
    console.log("FRONTEND AXIOS FUNCTION NAME -> test -> getUserTestAttempts");
    const response = await api.get(`/user/testList`,
      {
        headers: {
          authtoken: authtoken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user test attempts:", error);
    throw error.response?.data?.error || "Failed to fetch test attempts";
  }
};

export const getTestPercentile = async (testId, authtoken) => {
  try {
    console.log("FRONTEND AXIOS FUNCTION NAME -> test -> getTestPercentile");
    const response = await api.get(`/user/testResult/${testId}`,
      {
        headers: {
          authtoken: authtoken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching test percentile:", error);
    throw error.response?.data?.error || "Failed to fetch test percentile";
  }
};
