import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom"; // Use Navigate instead of Redirect
import { currentAdmin } from "../../functions/auth";
import { LoadingOutlined } from "@ant-design/icons";

// DO NOT include BrowserRouter, Routes, or Route components here
const AdminRoute = ({ children }) => {
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user && user.token) {
      currentAdmin(user.token)
        .then((res) => {
          setOk(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log("ADMIN ROUTE ERROR", err);
          setOk(false);
          setLoading(false);
        });
    } else {
      setOk(false);
      setLoading(false);
    }
  }, [user]);

  if (loading) {
    return (
      <div className="container">
        <div className="row">
          <div className="col text-center pt-5">
            <LoadingOutlined style={{ fontSize: '48px' }} />
          </div>
        </div>
      </div>
    );
  }
  
  // Return children if authenticated, otherwise redirect to home
  return ok ? children : <Navigate to="/" />;
};

export default AdminRoute;
