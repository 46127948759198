import axios from 'axios';

const CACHE_PREFIX = 'api_cache_';
const DEFAULT_CACHE_TIME = 15 * 60 * 1000; // 15 minutes

class CacheManager {
  static set(key, data, maxAge = DEFAULT_CACHE_TIME) {
    try {
      const cacheData = {
        data,
        timestamp: Date.now(),
        maxAge
      };
      localStorage.setItem(CACHE_PREFIX + key, JSON.stringify(cacheData));
    } catch (error) {
      console.error('Cache storage error:', error);
      // Clear some cache if storage is full
      this.pruneCache();
    }
  }

  static get(key) {
    try {
      const cached = localStorage.getItem(CACHE_PREFIX + key);
      if (!cached) return null;

      const { data, timestamp, maxAge } = JSON.parse(cached);
      if (Date.now() - timestamp > maxAge) {
        localStorage.removeItem(CACHE_PREFIX + key);
        return null;
      }
      return data;
    } catch (error) {
      console.error('Cache retrieval error:', error);
      return null;
    }
  }

  static clear() {
    try {
      Object.keys(localStorage)
        .filter(key => key.startsWith(CACHE_PREFIX))
        .forEach(key => localStorage.removeItem(key));
    } catch (error) {
      console.error('Cache clear error:', error);
    }
  }
  
  // New method to prune old cache entries when storage is getting full
  static pruneCache() {
    try {
      const cacheEntries = Object.keys(localStorage)
        .filter(key => key.startsWith(CACHE_PREFIX))
        .map(key => ({
          key,
          timestamp: JSON.parse(localStorage.getItem(key))?.timestamp || 0
        }))
        .sort((a, b) => a.timestamp - b.timestamp);
      
      // Remove oldest 20% of cache entries
      const removeCount = Math.ceil(cacheEntries.length * 0.2);
      cacheEntries.slice(0, removeCount).forEach(entry => 
        localStorage.removeItem(entry.key)
      );
    } catch (error) {
      console.error('Cache pruning error:', error);
    }
  }
}

// Create axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add caching interceptor with async/await
api.interceptors.request.use(async (config) => {
  // Don't cache non-GET requests
  if (config.method !== 'get') return config;
  
  // Don't cache auth routes
  if (config.url.match(/\/(auth|login|register)\//)) return config;

  const cacheKey = `${config.method}_${config.url}`;
  const cachedData = CacheManager.get(cacheKey);
  
  if (cachedData) {
    config.adapter = () => Promise.resolve({
      data: cachedData,
      status: 200,
      statusText: 'OK',
      headers: config.headers,
      config,
      request: {}
    });
  }
  
  return config;
});

// Cache successful GET responses
api.interceptors.response.use((response) => {
  if (response.config.method === 'get' && !response.config.url.match(/\/(auth|login|register)\//)) {
    const cacheKey = `${response.config.method}_${response.config.url}`;
    CacheManager.set(cacheKey, response.data);
  }
  return response;
});

// Add auth token interceptor with modern headers
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response;
  },
  async (error) => {
    // Handle errors globally with async/await
    if (error.response) {
      // Server responded with error status
      const status = error.response.status;
      
      // Handle specific HTTP status codes
      if (status === 401) {
        // Unauthorized - token expired
        localStorage.removeItem('token');
        // Redirect to login page
        window.location.href = '/login?session=expired';
      } else if (status === 403) {
        // Forbidden - insufficient permissions
        console.error('Access forbidden:', error.response.data.message);
      } else if (status === 404) {
        // Not found
        console.error('Resource not found');
      } else if (status === 500) {
        // Server error
        console.error('Server error:', error.response.data);
      }
    } else if (error.request) {
      // Request made but no response received
      console.error('Network error - no response:', error.request);
    } else {
      // Error in setting up the request
      console.error('Request setup error:', error.message);
    }
    
    // Re-throw for component-level handling
    return Promise.reject(error);
  }
);

// Export cache manager and api instance
export { CacheManager };
export default api;