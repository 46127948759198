import axios from "axios";
import api, { CacheManager } from "./api";

//get list of all the books
export const getBooks = async () => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> book -> getBooks");

  const response = await api.get(`/books`); //making request to endpoing books to list all books
  console.log("books received from backend:");
  return response;
};

// get details of selected book
export const getBook = async (_id, userId, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> book -> getBook");

  const headers = {};
  const params = {};

  if (authtoken) {
    headers.authtoken = authtoken;
  }

  if (userId) {
    params.userId = userId;
  }

  try {
    const response = await api.get(`/book/${_id}`,
      {
        headers: headers, // Use 'headers' object defined above
        params: params, // Use 'params' object defined above
      }
    );

    console.log("Specific Book response from backend:");
    return response.data;
  } catch (error) {
    console.error("Actual Network/HTTP Error:", error);
    throw error; // Or handle based on your application's logic
  }
};
// remove selected book
export const removeBook = async (id, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> book -> removeBooks");

  return await axios.delete(`${process.env.REACT_APP_API}/book/${id}`, {
    headers: {
      authtoken: authtoken,
    },
  });
};

// update details of selected book
export const updateBook = async (_id, book, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> book -> updateBook");

  return await axios.put(`${process.env.REACT_APP_API}/book/${_id}`, book, {
    headers: {
      authtoken: authtoken,
    },
  });
};

//create a new book
export const createBook = async (book, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> book -> createBook");

  return await axios.post(`${process.env.REACT_APP_API}/book`, book, {
    headers: {
      authtoken: authtoken,
    },
  }); //making request to endpoing book create given book details
};

export const getBooksBySubject = async (_id) => {
  try {
    console.log("FRONTEND AXIOS FUNCTION NAME -> book -> getBooksBySubject");
    const response = await api.get(`/books?subjectId=${_id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};
export const getBooksByExam = async (_id) => {
  try {
    console.log("FRONTEND AXIOS FUNCTION NAME -> book -> getBooksByExam");

    const response = await api.get(`/books?examId=${_id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

export const getBooksByBoard = async (_id) => {
  try {
    console.log("FRONTEND AXIOS FUNCTION NAME -> book -> getBooksByBoard");

    const response = await api.get(`/books?boardId=${_id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};
