import axios from "axios";
import api, { CacheManager } from "./api";

//get list of all the Classes
export const getClasses = async () => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> class -> getClasses");

  return await api.get(`/classes`); //making request to endpoing Class to list all Class
};

//get details of selected Class
export const getClass = async (_id) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> class -> getClass");

  return await api.get(`/class/${_id}`); //making request to endpoing Class with Slug to find given Class details
};

//remove selected Class
export const removeClass = async (_id, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> class -> removeClass");

  return await axios.delete(`${process.env.REACT_APP_API}/class/${_id}`, {
    headers: {
      authtoken,
    },
  }); //making request to endpoing Class with Slug to find given Class details adn delete it
};

//update details of selected Class
export const updateClass = async (_id, newclass, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> class -> updateClass");

  return await axios.put(
    `${process.env.REACT_APP_API}/class/${_id}`,
    newclass,
    {
      headers: {
        authtoken,
      },
    }
  ); //making request to endpoing Class with Slug to find given Class details
};

//create a new Class
export const createClass = async (newclass, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> class -> createClass");

  return await axios.post(`${process.env.REACT_APP_API}/class`, newclass, {
    headers: {
      authtoken: authtoken,
    },
  }); //making request to endpoing Class with Slug to find given Class details
};

export const getClassesByBoard = async (_id) => {
  try {
    console.log("FRONTEND AXIOS FUNCTION NAME -> class -> getClassesByBoard");

    const response = await api.get(`/classes?boardId=${_id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};
