import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown, Dropdown, Badge } from "react-bootstrap";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { getBoardsByBU } from "../../functions/board";
import { getClassesByBoard } from "../../functions/class";
import { getSubjectsByClass, getSubjects } from "../../functions/subject";
import { getBooksBySubject } from "../../functions/book";
import { getExams } from "../../functions/exam";
import { getTestExams } from "../../functions/test";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BellOutlined,
  BellFilled,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import axios from "axios";
import "./Header.css";

const auth = getAuth();

const Header = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [hasNotifications, setHasNotifications] = useState(false);
  const [booksBySubject, setBooksBySubject] = useState({});
  const [examList, setExamList] = useState([]);
  const [examTestList, setExamTestList] = useState([]);
  const [buList] = useState([
    "K12 Education",
    //"Entrance Exams",
    //"Competitive Exams",
  ]);
  const [boardList, setBoardList] = useState({});
  const [classList, setClassList] = useState({});
  const [subjectList, setSubjectList] = useState({});
  const [bookSubjectList, setBookSubjectList] = useState([]);

  let dispatch = useDispatch();
  let { user, cart } = useSelector((state) => ({ ...state }));

  let navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchNotifications();
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const menu = document.getElementById("basic-navbar-nav");
      if (menu && !menu.contains(event.target)) {
        menu.classList.remove("show");
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const fetchNotifications = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/notifications/${user._id}`
      );
      setNotifications(data.notifications);
      const unread = data.notifications.filter((n) => !n.read).length;
      setUnreadCount(unread);
      setHasNotifications(unread > 0);
    } catch (error) {
      console.error("Error fetching notifications", error);
    }
  };

  const handleNotificationClick = async (notification) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API}/notifications/mark-read/${notification._id}`,
        { userId: user._id }
      );

      navigate(notification.entityUrl);

      fetchNotifications();
    } catch (error) {
      console.error("Error marking notification as read", error);
    }
  };

  const handleBuHover = async (bu) => {
    if (!boardList[bu]) {
      const boards = await getBoardsByBU(bu);
      setBoardList((prev) => ({ ...prev, [bu]: boards }));
    }
  };

  const handleBoardHover = async (boardId) => {
    if (!classList[boardId]) {
      const classes = await getClassesByBoard(boardId);
      setClassList((prev) => ({ ...prev, [boardId]: classes }));
    }
  };

  const handleClassHover = async (classId) => {
    if (!subjectList[classId]) {
      const subjects = await getSubjectsByClass(classId);
      setSubjectList((prev) => ({ ...prev, [classId]: subjects }));
    }
  };

  const handleBooksHover = async () => {
    try {
      const booksubjects = await getSubjects();
      setBookSubjectList(booksubjects.data);
    } catch (error) {
      console.error("Error fetching subjects", error);
    }
  };

  const handleSubjectHover = async (subjectId) => {
    if (!booksBySubject[subjectId]) {
      try {
        const books = await getBooksBySubject(subjectId);
        setBooksBySubject((prev) => ({ ...prev, [subjectId]: books }));
      } catch (error) {
        console.error("Error fetching books by subject", error);
      }
    }
  };

  const handleExamsHover = async () => {
    try {
      const exams = await getExams();
      setExamList(exams.data);
    } catch (error) {
      console.error("Error fetching exams", error);
    }
  };

  const handleTestsHover = async () => {
    try {
      const tests = await getTestExams();
      setExamTestList(tests.data);
    } catch (error) {
      console.error("Error fetching exams", error);
    }
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        dispatch({
          type: "LOGOUT",
          payload: null,
        });
        navigate("/login");
      })
      .catch((err) => {
        dispatch({ type: "LOGOUT_FAIL", err });
      });
  };

  return (
    <header style={{ position: "fixed", top: 0, left: 0, width: "100%", zIndex: 1000, backgroundColor: "#ffffff", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", padding: "10px 20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div className="logo">
        <Link to="/">
          <img
            src="https://pub-09328fb7cd7b4b94aaf562e6bb66ceaf.r2.dev/prep_Chimp_logo.png"
            alt="PrepChimp"
            style={{ width: "40px", height: "auto" }}
          />
        </Link>
      </div>

      <Navbar expand="lg" className="w-100" style={{ marginLeft: "20px" }}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" style={{ display: "flex", flexGrow: 1 }}>
            {buList && buList.map((bu) => (
              <NavDropdown
                key={bu}
                title={bu}
                id={`bu-dropdown-${bu}`}
                onMouseEnter={() => handleBuHover(bu)}
              >
                <div className="multi-column-dropdown">
                  {boardList[bu]?.map((board) => (
                    <NavDropdown
                      key={board._id}
                      title={board.name}
                      id={`board-dropdown-${board._id}`}
                      onMouseEnter={() => handleBoardHover(board._id)}
                      className="custom-dropdown left-aligned"
                    >
                      {classList[board._id]?.map((classs) => (
                        <NavDropdown
                          key={classs._id}
                          title={classs.name}
                          id={`class-dropdown-${classs._id}`}
                          onMouseEnter={() => handleClassHover(classs._id)}
                          className="custom-dropdown left-aligned"
                        >
                          {subjectList[classs._id]?.map((subject) => (
                            <NavDropdown.Item
                              key={subject._id}
                              as={Link}
                              to={`/subject/${subject._id}`}
                              className="left-aligned-item"
                            >
                              {subject.name}
                            </NavDropdown.Item>
                          ))}
                        </NavDropdown>
                      ))}
                    </NavDropdown>
                  ))}
                </div>
              </NavDropdown>
            ))}

            <NavDropdown title="Books" id="books-dropdown" onMouseEnter={handleBooksHover}>
              {Array.isArray(bookSubjectList) &&
                bookSubjectList.map((subject) => (
                  <NavDropdown
                    key={subject._id}
                    title={subject.name}
                    id={`subject-dropdown-${subject._id}`}
                    onMouseEnter={() => handleSubjectHover(subject._id)}
                    className="custom-dropdown left-aligned"
                  >
                    {booksBySubject[subject._id]?.map((book) => (
                      <NavDropdown.Item
                        key={book._id}
                        as={Link}
                        to={`/book/${book._id}`}
                        className="left-aligned-item"
                      >
                        {book.title}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                ))}
            </NavDropdown>

            <NavDropdown title="Exams" id="exams-dropdown" onMouseEnter={handleExamsHover}>
              {Array.isArray(examList) &&
                examList.map((exam) => (
                  <NavDropdown.Item
                    key={exam._id}
                    as={Link}
                    to={`/exam/${exam._id}`}
                    className="left-aligned-item"
                  >
                    {exam.name}
                  </NavDropdown.Item>
                ))}
            </NavDropdown>

            <NavDropdown title="Mock Test" id="tests-dropdown" onMouseEnter={handleTestsHover}>
              {Array.isArray(examTestList.exams) &&
                examTestList.exams.map((exam) => (
                  <NavDropdown.Item
                    key={exam._id}
                    as={Link}
                    to={`/mocktests/${exam._id}`}
                    className="left-aligned-item"
                  >
                    {exam.name}
                  </NavDropdown.Item>
                ))}
            </NavDropdown>
          </Nav>

          <Nav style={{ display: "flex", alignItems: "center" }}>
            <button
              style={{ 
                backgroundColor: "#2a2f4f", 
                color: "#ffffff", 
                border: "none", 
                padding: "8px 16px",
                borderRadius: "8px",
                marginRight: "10px",
                fontWeight: "600",
                cursor: "pointer"
              }}
              onClick={() => navigate("/user/questions")}
            >
              ASK A QUESTION
            </button>
            
            {user && (
              <div style={{ position: "relative", margin: "0 10px", cursor: "pointer" }}>
                <span>
                  {unreadCount > 0 && (
                    <span style={{ 
                      position: "absolute", 
                      top: "-8px", 
                      right: "-8px", 
                      backgroundColor: "#e53e3e", 
                      color: "#fff", 
                      borderRadius: "50%", 
                      padding: "3px 6px", 
                      fontSize: "12px" 
                    }}>
                      {unreadCount}
                    </span>
                  )}
                  {hasNotifications ? <BellFilled /> : <BellOutlined />}
                </span>
              </div>
            )}

            <Link to="/cart" style={{ margin: "0 10px", position: "relative" }}>
              <ShoppingCartOutlined style={{ fontSize: "22px" }} />
              {cart && cart.length > 0 && (
                <span style={{ 
                  position: "absolute", 
                  top: "-8px", 
                  right: "-8px", 
                  backgroundColor: "#e53e3e", 
                  color: "#fff", 
                  borderRadius: "50%", 
                  padding: "3px 6px", 
                  fontSize: "12px" 
                }}>
                  {cart.length}
                </span>
              )}
            </Link>

            {!user && (
              <>
                <Link to="/register" style={{ margin: "0 10px", textDecoration: "none" }}>Register</Link>
                <Link to="/login" style={{ margin: "0 10px", textDecoration: "none" }}>Login</Link>
              </>
            )}
            
            {user && (
              <NavDropdown title={user.email && user.email.split("@")[0]} id="basic-nav-dropdown">
                {user.role === "student" && (
                  <>
                    <NavDropdown.Item as={Link} to="/user/profile" className="right-aligned-item">
                      My Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/user/mycourses" className="right-aligned-item">
                      My Courses
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/user/mynotes" className="right-aligned-item">
                      My Notes
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/user/my-questions" className="right-aligned-item">
                      My Questions
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/subscribe" className="right-aligned-item">
                      Subscribe More
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/user/mysettings" className="right-aligned-item">
                      My Settings
                    </NavDropdown.Item>
                  </>
                )}
                {user.role === "admin" && (
                  <NavDropdown.Item as={Link} to="/admin/dashboard" className="right-aligned-item">
                    Dashboard
                  </NavDropdown.Item>
                )}
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout} className="right-aligned-item">Logout</NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;