import React, { useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { currentUser } from "./functions/auth";
import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { loadCart } from "./pages/Cart/cartActions.js";
import ChatBot from "./components/chat/ChatBot.js";
import ErrorBoundary from './components/ErrorBoundary';

import './index.css';
// Admin user check component import
import AdminRoute from './components/routes/AdminRoute.js';
// Student user check component import
import StudentRoute from './components/routes/StudentRoute.js';
import { LoadingOutlined } from "@ant-design/icons";
import Header from "./components/nav/Header";  // Make sure Header is imported

const Login = lazy(() => import("./pages/auth/Login"));
const Register = lazy(() => import("./pages/auth/Register"));
const Home = lazy(() => import("./pages/Home"));
const RegisterComplete = lazy(() => import("./pages/auth/RegisterComplete"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const Sidebar = lazy(() => import("./components/nav/adminnav/Sidebar"));
const BoardList = lazy(() => import("./pages/Board"));
const BoardPage = lazy(() => import("./pages/BoardPage"));
const ClassList = lazy(() => import("./pages/Class"));
const Checkout = lazy(() => import("./pages/Checkout"));
const ClassPage = lazy(() => import("./pages/ClassPage"));
const SubjectList = lazy(() => import("./pages/Subject"));
const SubjectPage = lazy(() => import("./pages/SubjectPage"));
const SubjectDetailPage = lazy(() => import("./pages/SubjectDetailPage"));
const ChapterList = lazy(() => import("./pages/Chapter"));
const ChapterPage = lazy(() => import("./pages/ChapterPage"));
const TopicList = lazy(() => import("./pages/Topic"));
const TopicPage = lazy(() => import("./pages/TopicPage"));
const ContentList = lazy(() => import("./pages/Content"));
const ContentPage = lazy(() => import("./pages/ContentPage"));
const BookList = lazy(() => import("./pages/book.js"));
const BookPage = lazy(() => import("./pages/BookPage"));
const ExamList = lazy(() => import("./pages/exam.js"));
const Exam = lazy(() => import("./pages/ExamPage.js"));
const ExamQuestion = lazy(() => import("./pages/ExamQuestions.js"));
const BookSolutionPage = lazy(() => import("./pages/BookSolution.js"));
const QuestionList = lazy(() => import('./pages/questionList.js'));
const UserQuestionsPage = lazy(() => import('./pages/userQuestionsPage.js'));
const UserQuestion = lazy(() => import('./pages/userQuestionPage.js'));
const Subscribe = lazy(() => import('./pages/Subscribe.js'));
const Payment = lazy(() => import('./pages/Payment.js'));
const Cart = lazy(() => import('./pages/Cart/Cart.js'));
const MockTests = lazy(() => import('./pages/MockTests.js'));
const MockTestsPreview = lazy(() => import('./pages/test/testPreview/TestPreview.js'));
const MockTestsInstructions = lazy(() => import('./pages/test/testPreview/TestInstructions.js'));
const MockTestResults = lazy(() => import('./pages/students/MockTestResults.js'));
const DocsPage = lazy(() => import('./pages/DocPage.js'));

// Students Pages const
const Onboarding = lazy(() => import('./pages/students/Onboarding.js'));
const EditPreferences = lazy(() => import('./pages/students/EditPreferences.js'));
const MyCourses = lazy(() => import('./pages/students/MyCourses.js'));
const MyNotes = lazy(() => import('./pages/students/MyNotes.js'));
const MyQuestions = lazy(() => import('./pages/students/MyQuestions.js'));
const MySettings = lazy(() => import('./pages/students/MySettings.js'));
const PasswordReset = lazy(() => import('./pages/students/PasswordReset.js'));
const History = lazy(() => import('./pages/students/OrderHistory.js'));
const AttemptTest = lazy(() => import('./pages/test/testPreview/HandleLiveTest.js'));
const TestResults = lazy(() => import('./pages/test/result/TestResultsPage.js'));
const Profile = lazy(() => import('./pages/students/MyProfile.js'));
const MySubscriptions = lazy(() => import('./pages/students/MySubscriptions.js'));

// Admin Pages const
const AdminDashboard = lazy(() => import('./pages/admin/adminDashboard.js'));
const BoardCreate = lazy(() => import('./pages/admin/k12/board/CreateBoard'));
const ClassCreate = lazy(() => import('./pages/admin/k12/class/ClassCreate'));
const BoardUpdate = lazy(() => import('./pages/admin/k12/board/UpdateBoard'));
const ClassUpdate = lazy(() => import('./pages/admin/k12/class/UpdateClass'));
const SubjectCreate = lazy(() => import('./pages/admin/k12/subject/SubjectCreate.js'));
const SubjectUpdate = lazy(() => import('./pages/admin/k12/subject/UpdateSubject.js'));
const ChapterCreate = lazy(() => import('./pages/admin/k12/chapter/ChapterCreate.js'));
const ChapterUpdate = lazy(() => import('./pages/admin/k12/chapter/ChapterUpdate.js'));
const TopicCreate = lazy(() => import('./pages/admin/k12/topic/TopicCreate.js'));
const UpdateTopic = lazy(() => import('./pages/admin/k12/topic/TopicUpdate.js'));
const ContentCreate = lazy(() => import('./pages/admin/k12/content/ContentCreate.js'));
const ContentUpdate = lazy(() => import('./pages/admin/k12/content/ContentUpdate.js'));
const BookCreate = lazy(() => import('./pages/admin/k12/book/CreateBook.js'));
const UpdateBook = lazy(() => import('./pages/admin/k12/book/UpdateBook.js'));
const CreateBookChapter = lazy(() => import('./pages/admin/k12/chapter/CreateBookChapter.js'));
const ExamCreate = lazy(() => import('./pages/admin/k12/exam/CreateExam.js'));
const CreateQuestion = lazy(() => import('./pages/admin/k12/question/CreateQuestions.js'));
const CreateQuestionCsv = lazy(() => import('./pages/admin/k12/question/CreateQuestionCsv.js'));
const UpdateQuestionCsv = lazy(() => import('./pages/admin/k12/question/UpdateQuestionCsv.js'));
const ExamYear = lazy(() => import("./pages/admin/k12/exam/CreateExamYear.js"));
const CreateTest = lazy(() => import('./pages/admin/k12/test/CreateTest.js'));
const CreateChapterTest = lazy(() => import('./pages/admin/k12/test/CreateChapterTest.js'));
const CreateSubjectTest = lazy(() => import('./pages/admin/k12/test/CreateSubjectTest.js'));
const BulkImageUpload = lazy(() => import('./pages/admin/bulkImageUpload.js'));

const usePageTracking = () => {
  const location = useLocation();
  
  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-X4BQ3T2KME', {
        page_path: location.pathname,
      });
    }
  }, [location]);
};

const App = () => {
  const dispatch = useDispatch();
  usePageTracking(); // Initialize tracking

  // To check Firebase auth state
  useEffect(() => {
    dispatch(loadCart());
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        console.log("app.js -> user");
        currentUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name: res.data.name,
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id,
              },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
    // Cleanup
    
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Suspense fallback={
      <div className="col text-center p5">
        _Loading<LoadingOutlined />_
      </div>
    }>
      <ErrorBoundary showDetails={process.env.NODE_ENV === 'development'}>
        <div className="main-container">
          {/* Header */}
          <Header /> {/* Include Header here to display on all pages */}

          {/* Sidebar for Admin (if needed) */}
          <div className="content-wrapper">
            <ToastContainer />
            <Routes>
              {/* General Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/register/complete" element={<RegisterComplete />} />
              <Route path="/forgot/password" element={<ForgotPassword />} />
              <Route path="/boards" element={<BoardList />} />
              <Route path="/boards/:_id" element={<BoardPage />} />
              <Route path="/classes" element={<ClassList />} />
              <Route path="/class/:_id" element={<ClassPage />} />
              <Route path="/subjects" element={<SubjectList />} />
              <Route path="/subject/:_id" element={<SubjectDetailPage />} />
              <Route path="/chapters" element={<ChapterList />} />
              <Route path="/chapter/:_id" element={<ChapterPage />} />
              <Route path="/topics" element={<TopicList />} />
              <Route path="/topic/:_id" element={<TopicPage />} />
              <Route path="/contents" element={<ContentList />} />
              <Route path="/content/:_id" element={<ContentPage />} />
              <Route path="/books" element={<BookList />} />
              <Route path="/book/:_id" element={<BookSolutionPage />} />
              <Route path="/exams" element={<ExamList />} />
              <Route path="/exam/:_id" element={<Exam />} />
              <Route path="/exam-questions" element={<ExamQuestion />} />
              <Route path="/questions" element={<QuestionList />} />
              <Route path="/user/questions" element={<UserQuestionsPage />} />
              <Route path="/user/question/:_id" element={<UserQuestion />} />
              <Route path="/subscribe" element={<Subscribe />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/mocktests/:_id" element={<MockTests />} />
              <Route path="/livetests/:_id" element={<MockTestsPreview />} />
              <Route path="/livemocktest/instructions" element={<MockTestsInstructions />} />
              <Route path="/documentation" element={<DocsPage />} />

              {/* Student Routes */}
              <Route path="/user/edit-preferences" element={
                <StudentRoute>
                  <EditPreferences />
                </StudentRoute>
              } />
              <Route path="/user/onboarding" element={
                <StudentRoute>
                  <Onboarding />
                </StudentRoute>
              } />
              <Route path="/user/mycourses" element={
                <StudentRoute>
                  <MyCourses />
                </StudentRoute>
              } />
              <Route path="/user/mysettings" element={
                <StudentRoute>
                  <MySettings />
                </StudentRoute>
              } />
              <Route path="/user/mynotes" element={
                <StudentRoute>
                  <MyNotes />
                </StudentRoute>
              } />
              <Route path="/user/my-questions" element={
                <StudentRoute>
                  <MyQuestions />
                </StudentRoute>
              } />
              <Route path="/user/password-reset" element={
                <StudentRoute>
                  <PasswordReset />
                </StudentRoute>
              } />
              <Route path="/checkout" element={
                <StudentRoute>
                  <Checkout />
                </StudentRoute>
              } />
              <Route path="/payment" element={
                <StudentRoute>
                  <Payment />
                </StudentRoute>
              } />
              <Route path="/user/purchase-history" element={
                <StudentRoute>
                  <History />
                </StudentRoute>
              } />
              <Route path="/livemocktest/exam/:testId" element={
                <StudentRoute>
                  <AttemptTest />
                </StudentRoute>
              } />
              <Route path="/test/results" element={
                <StudentRoute>
                  <TestResults />
                </StudentRoute>
              } />
              <Route path="/user/profile" element={
                <StudentRoute>
                  <Profile />
                </StudentRoute>
              } />
              <Route path="/user/sub-history" element={
                <StudentRoute>
                  <MySubscriptions />
                </StudentRoute>
              } />
              <Route path="/user/mocktests" element={
                <StudentRoute>
                  <MockTestResults />
                </StudentRoute>
              } />

              {/* Admin Routes */}
              <Route path="/admin/dashboard" element={
                <AdminRoute>
                  <AdminDashboard />
                </AdminRoute>
              } />
              <Route path="/admin/board" element={
                <AdminRoute>
                  <BoardCreate />
                </AdminRoute>
              } />
              <Route path="/admin/board/:_id" element={
                <AdminRoute>
                  <BoardUpdate />
                </AdminRoute>
              } />
              <Route path="/admin/class" element={
                <AdminRoute>
                  <ClassCreate />
                </AdminRoute>
              } />
              <Route path="/admin/class/:_id" element={
                <AdminRoute>
                  <ClassUpdate />
                </AdminRoute>
              } />
              <Route path="/admin/subject" element={
                <AdminRoute>
                  <SubjectCreate />
                </AdminRoute>
              } />
              <Route path="/admin/subject/:_id" element={
                <AdminRoute>
                  <SubjectUpdate />
                </AdminRoute>
              } />
              <Route path="/admin/chapter" element={
                <AdminRoute>
                  <ChapterCreate />
                </AdminRoute>
              } />
              <Route path="/admin/chapter/:_id" element={
                <AdminRoute>
                  <ChapterUpdate />
                </AdminRoute>
              } />
              <Route path="/admin/topic" element={
                <AdminRoute>
                  <TopicCreate />
                </AdminRoute>
              } />
              <Route path="/admin/topic/:_id" element={
                <AdminRoute>
                  <UpdateTopic />
                </AdminRoute>
              } />
              <Route path="/admin/content" element={
                <AdminRoute>
                  <ContentCreate />
                </AdminRoute>
              } />
              <Route path="/admin/content/:_id" element={
                <AdminRoute>
                  <ContentUpdate />
                </AdminRoute>
              } />
              <Route path="/admin/book" element={
                <AdminRoute>
                  <BookCreate />
                </AdminRoute>
              } />
              <Route path="/admin/book/:_id" element={
                <AdminRoute>
                  <UpdateBook />
                </AdminRoute>
              } />
              <Route path="/admin/bookChapter" element={
                <AdminRoute>
                  <CreateBookChapter />
                </AdminRoute>
              } />
              <Route path="/admin/exam" element={
                <AdminRoute>
                  <ExamCreate />
                </AdminRoute>
              } />
              <Route path="/admin/examYear" element={
                <AdminRoute>
                  <ExamYear />
                </AdminRoute>
              } />
              <Route path="/admin/question" element={
                <AdminRoute>
                  <CreateQuestion />
                </AdminRoute>
              } />
              <Route path="/admin/question-Bulk" element={
                <AdminRoute>
                  <CreateQuestionCsv />
                </AdminRoute>
              } />
              <Route path="/admin/question-Bulk-Update" element={
                <AdminRoute>
                  <UpdateQuestionCsv />
                </AdminRoute>
              } />
              <Route path="/admin/test" element={
                <AdminRoute>
                  <CreateTest />
                </AdminRoute>
              } />
              <Route path="/admin/chaptertest" element={
                <AdminRoute>
                  <CreateChapterTest />
                </AdminRoute>
              } />
              <Route path="/admin/subjecttest" element={
                <AdminRoute>
                  <CreateSubjectTest />
                </AdminRoute>
              } />
              <Route path="/admin/image-upload" element={
                <AdminRoute>
                  <BulkImageUpload />
                </AdminRoute>
              } />
            </Routes>
          </div>
          {/* Global ChatBot */}
          {/* <ChatBot /> */}
        </div>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;
