import axios from "axios";
import api, { CacheManager } from "./api";



// get details of selected exam
export const getExam = async (_id) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> exam -> getExam");

  try {
    const response = await api.get(`/exam/${_id}`);
    console.log('Specific Exam response from backend:', response);
    return response.data;
  } catch (error) {
    // Only handle true network or HTTP errors 
    console.error('Actual Network/HTTP Error:', error); 
    throw error; // Or handle based on your application's logic
  }
};


// remove selected exam
export const removeExam = async (id, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> exam -> removeExam");

  return await axios.delete(`${process.env.REACT_APP_API}/exam/${id}`, {
    headers: {
      authtoken: authtoken, 
    },
  });
};

// update details of selected exam
export const updateExam = async (_id, exam, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> exam -> updateExam");

  return await axios.put(`${process.env.REACT_APP_API}/exam/${_id}`, exam, {
    headers: {
      authtoken: authtoken,
    },
  }); 
};



export const createExam = async (exam, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> exam -> createExam");

  return axios.post(`${process.env.REACT_APP_API}/exam`, exam, {
    headers: {
      authtoken: authtoken,
    },
  });
};



export const getExamsBySubject = async (subjectId) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> exam -> getExamsBySubject");

  const response = await api.get(`/exams?subjectId=${subjectId}`); //making request to endpoing exams to list all exams
  console.log('exams by subject received from backend:',response.data);
  return response;
};

export const getExamsByBook = async (bookId) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> exam -> getExamsByBook");

  const response = await api.get(`/exams?bookId=${bookId}`); //making request to endpoing exams to list all exams
  console.log('exams by Book received from backend:',response.data);
  return response;
};

//get list of all the exams
export const getExams = async () => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> exam -> getExams");

  const response = await api.get(`/exams`); //making request to endpoing exams to list all exams
  console.log('exams received from backend:',response.data);
  return response;
};


export const getExamsByType = async (examType) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> exam -> getExamsByType");

  const response = await api.get(`/exams?examType=${examType}`); //making request to endpoing exams to list all exams
  console.log('exams received from backend:',response.data);
  return response;
};

//create a new exam Year
export const createExamYear = async (formData, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> exam -> createExamYear");

  return await axios.post(`${process.env.REACT_APP_API}/examYear`, formData, {
    headers: {
      authtoken: authtoken,
    },
  }); //making request to endpoing exam create given exam details
};

export const getExamYears = async (_id) => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> exam -> getExamYears");
  try {
    const response = await api.get(`/examyear/${_id}`);
    console.log('Specific Exam response from backend:', response);
    return response.data;
  } catch (error) {
    // Only handle true network or HTTP errors 
    console.error('Actual Network/HTTP Error:', error); 
    throw error; // Or handle based on your application's logic
  }
};

