import axios from "axios";
import api, { CacheManager } from "./api";

//get list of all the boards
export const getBoards = async () => {
  console.log("FRONTEND AXIOS FUNCTION NAME -> board -> getBoards");

  const response = await api.get(`/boards`); //making request to endpoing boards to list all boards
  console.log('boards received from backend:');
  return response;
};

// get details of selected board
export const getBoard = async (_id) => {
  console.log("FRONTEND AXIOS FUNCTION NAME board getBoard");

  try {
    const response = await api.get(`/board/${_id}`);
    console.log('Specific Board response from backend:');
    return response.data;
  } catch (error) {
    // Only handle true network or HTTP errors 
    console.error('Actual Network/HTTP Error:', error); 
    throw error; // Or handle based on your application's logic
  }
};


// remove selected board
export const removeBoard = async (id, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME board removeBoard");

  return await axios.delete(`${process.env.REACT_APP_API}/board/${id}`, {
    headers: {
      authtoken: authtoken, 
    },
  });
};

// update details of selected board
export const updateBoard = async (_id, board, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME board updateBoard");

  return await axios.put(`${process.env.REACT_APP_API}/board/${_id}`, board, {
    headers: {
      authtoken: authtoken,
    },
  }); 
};

//create a new board
export const createBoard = async (board, authtoken) => {
  console.log("FRONTEND AXIOS FUNCTION NAME board createBoard");

  return await axios.post(`${process.env.REACT_APP_API}/board`, board, {
    headers: {
      authtoken: authtoken,
    },
  }); //making request to endpoing board create given board details
};

export const getBoardClasses = async (id) => {
  console.log("FRONTEND AXIOS FUNCTION NAME board getBoardClasses");

  return await api.get(`/board/classes/${id}`); //making request to endpoing board with Slug to find given board details
};

// Function to get boards by a specific Business Unit (BU)
export const getBoardsByBU = async (bu) => {
  console.log("FRONTEND AXIOS FUNCTION NAME: getBoardsByBU");

  try {
    const response = await api.get(`/boards`, {
      params: { bu }, // Sending the BU as a query parameter
    });

    console.log(`Boards received for BU "${bu}":`);
    return response.data; // Return the data directly
  } catch (error) {
    console.error("Error fetching boards by BU:", error);
    throw error; // You may choose to handle this error in the calling component
  }
};